/* @import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'; */

/* You can add global styles to this file, and also import other style files */
@import '../../../../../node_modules/primeicons/primeicons.css';
/*@import '../node_modules/primeng/resources/themes/omega/theme.css';*/
@import '../../../../../node_modules/primeng/resources/primeng.min.css';
/* @import '../node_modules/primeng/resources/themes/nova-light/theme.css'; */
@import '../../../../../node_modules/primeng/resources/themes/saga-blue/theme.css';


/*@import '../../yii/web/lib/mdi/css/materialdesignicons.min.css';*/
@import '../../../../../node_modules/@mdi/font/css/materialdesignicons.min.css';

@import '../../../../../node_modules/ol/ol.css';

@import '../../../../../node_modules/webdatarocks/webdatarocks.min.css';

@import '../../../../../node_modules/flag-icons/css/flag-icons.min.css';

/* body { */
  /* line-height: 1; */
  /* font-family: "Open Sans", "Helvetica Neue", sans-serif; */
  /* font-size: 12px; */
/* } */

/*OVERWRITE PRIMENG's NOVA-LIGHT THEME*/

/* button */
.p-button{
  font-size:12px;
  padding: 0.2rem 0.5rem;
}
.p-button .mdi{
  font-size: 14px;
  top: 10px;
}
.p-button .mdi::before{ /*to override style in company-css*/
  top:0;
}
.p-button.p-button-text-icon-left .p-button-text{
  padding: 0.38em 1em 0.4em 2em;
}
.p-splitbutton .p-button.p-splitbutton-menubutton{
  /* height: 28px; */
  padding: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5rem;
  color: inherit;
}
/* .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: inherit;
} */

/* form elements */
body .p-inputtext, .p-multiselect .p-multiselect-label{
  font-size: inherit;
  padding: .25em;
}
/* body .p-chkbox .p-chkbox-box, body .p-radiobutton .p-radiobutton-box{
  width: 1.125em;
  height: 1.125em;
} */
body .p-chkbox .p-chkbox-box .p-chkbox-icon{
  font-size: 1em;
}
body .p-component{
  font-size:12px;
}
/* body .p-radiobutton, body .p-chkbox{
  width: auto;
  height: auto;
  margin-right: .25em;
  margin-left: .25em;
} */
body .p-inputtext.ng-dirty.ng-invalid, body p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
body p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
body p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext, body p-chips.ng-dirty.ng-invalid > .p-inputtext,
body p-inputmask.ng-dirty.ng-invalid > .p-inputtext, body p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
/* body p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box, body p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,  */
body p-listbox.ng-dirty.ng-invalid .p-inputtext, body p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
body p-spinner.ng-dirty.ng-invalid > .p-inputtext, body p-selectbutton.ng-dirty.ng-invalid .p-button,
body p-togglebutton.ng-dirty.ng-invalid .p-button{
  border: none;
}
body .prime-ng-input .p-inputtext.ng-pristine.ng-invalid, body .prime-ng-input .p-inputtext.ng-dirty.ng-invalid, body .prime-ng-input p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
body .prime-ng-input p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
body .prime-ng-input p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext, body .prime-ng-input p-chips.ng-dirty.ng-invalid > .p-inputtext,
body .prime-ng-input p-inputmask.ng-dirty.ng-invalid > .p-inputtext, body .prime-ng-input p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
/* body .prime-ng-input p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box, body .prime-ng-input p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,  */
body .prime-ng-input p-listbox.ng-dirty.ng-invalid .p-inputtext, body .prime-ng-input p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
body .prime-ng-input p-spinner.ng-dirty.ng-invalid > .p-inputtext, body .prime-ng-input p-selectbutton.ng-dirty.ng-invalid .p-button,
body .prime-ng-input p-togglebutton.ng-dirty.ng-invalid .p-button{
  border: 1px solid #a6a6a6; /* original color of primeng inputs */
}


/* toolbar */
body .p-toolbar{
  padding: .25em .5em;
}

/* panel */
body .p-panel .p-panel-titlebar{
  padding: .5em .75em;
}

/* toast */
.p-toast-detail{
  white-space: pre-line;
}
.p-toast{
  width: 20em;
  width: fit-content !important;
  max-width: 50%;
}
body .p-toast a:link{
  text-decoration: none;
}

/* table and treetable */
.p-datatable-flex-scrollable, .p-datatable-flex-scrollable .p-datatable-scrollable-view, .p-datatable-flex-scrollable .p-datatable-scrollable-wrapper {
  flex: initial !important;
}

body .p-datatable .p-datatable-tbody > tr:focus,
body .p-treetable .p-treetable-tbody > tr:focus{
  outline: none;
}
body .p-datatable .p-datatable-tbody > tr.p-highlight,
body .p-treetable .p-treetable-tbody > tr.p-highlight{
  background-color: #007ad9 !important;
  color: #ffffff !important;
}
body p-treetable[ng-reflect-selection-mode="checkbox"] .p-treetable .p-treetable-tbody > tr.p-highlight {
    background-color: initial !important;
    color: initial !important;
}
body .p-datatable .p-editable-column input,
body .p-treetable .p-editable-column input {
  font-size: inherit;
}
.p-datatable td{
  word-break: break-all;
}
body .noWrappingDataGrid .p-datatable .p-datatable-tbody > tr > td,
body .noWrappingDataGrid .p-treetable .p-treetable-tbody > tr > td{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
body .p-datatable .p-datatable-tbody > tr > td .cell {
  overflow: hidden;
  width: 100%;
}
body .p-datatable .p-datatable-tbody  tr td,
body .p-treetable .p-treetable-tbody  tr td,
body .p-datatable .p-datatable-thead > tr > th,
body .p-treetable .p-treetable-thead > tr > th,
body .p-treetable .p-treetable-header,
body .p-datatable .p-datatable-header {
  padding: 0.5rem;
}

body .p-datatable .p-datatable-tbody>tr>td.p-cell-editing,
body .p-treetable .p-treetable-tbody>tr>td.p-cell-editing{
  padding: 0.571em 0.857em;
  overflow: visible;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 1rem;
  height: 1rem;
}

body .p-tooltip.dataGridTooltip {
  max-width: 400px;
}
body .p-tooltip.dataGridTooltip .p-tooltip-text{
  background-color: #ffffff;
  color: #6e6e6e;
  padding: 0.429em;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  width: fit-content;
  word-break: break-word;
}
body .p-tooltip.p-tooltip-right.dataGridTooltip .p-tooltip-arrow{
  border-right-color: #ffffff;
}
body .p-tooltip.p-tooltip-left.dataGridTooltip .p-tooltip-arrow{
  border-left-color: #ffffff;
}
body .p-datatable .search_highlight {
    color: #6e6e6e;
    background-color: #ffff00;
}
/* body .p-datatable table{
  border-spacing: 0;
  border-collapse: separate;
} */
/* body .p-datatable .p-datatable-tbody > tr > td{
  border-right: none;
  border-top: none!important;
}
body .p-datatable .p-datatable-thead > tr > th{
  border-right: none;
  border: 2px solid red !important;
}
/* body .p-datatable .p-datatable-tbody > tr > td:last-child,
body .p-datatable .p-datatable-thead > tr > th:last-child{
  border-right: 1px solid #c8c8c8;
} */
body .p-datatable .p-editable-column input:focus,
body .p-treetable .p-editable-column input:focus{
  outline: none;
  outline-offset: 0px;
  border: 1px solid #007ad9;
}

.p-treetable-flex-scrollable,
.p-treetable-flex-scrollable .p-treetable-scrollable-view,
.p-treetable-flex-scrollable .p-treetable-scrollable-wrapper{
  min-height: 0;
}

@-moz-document url-prefix(){
  body .p-datatable table{
    border-spacing: initial;
    border-collapse: collapse;
  }
}

/* tree */
body .p-tree .p-treenode-selectable.p-treenode-content{
  outline: none;
}
.p-tree{
  padding: 5px 20px 5px 5px !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content{
  padding: 0 !important;
}

/* file uploader */
.customUploaderWrapper {
  max-width: 400px;
}
.customUploaderWrapper .p-fileupload-row>div{
  padding: 1px;
}
.customUploaderWrapper .p-fileupload-row>div:first-child, .p-fileupload-row>div:nth-child(3){
  display: none;
}
.customUploaderWrapper .p-fileupload-row>div:nth-child(2){
  max-width: 310px;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.customUploaderWrapper .p-fileupload-content{
  max-height: 200px;
  overflow: auto;
}
.customUploaderWrapper .p-fileupload .p-messages-close{
  top: 0;
  right: 0;
  font-size: 14px;
}
.customUploaderWrapper .p-fileupload .p-fileupload-buttonbar .p-button{
  margin-right: 0;
}
.customUploaderWrapper .p-fileupload .p-fileupload-buttonbar p-button[icon="pi pi-upload"],
.customUploaderWrapper .p-fileupload .p-fileupload-buttonbar .p-fileupload-choose{
  margin-right: 8px;
}

.p-fileupload .p-fileupload-buttonbar {
  background: inherit;
  padding: 0;
}
.p-fileupload .p-button {
  line-height: 22px;
}
.p-fileupload .p-fileupload-content{
  max-height: 215px;
  overflow: auto;
  padding: 0.5rem;
  background: inherit;
}
.p-fileupload .p-fileupload-row>div{
  width: auto;
}

/* confirm dialog */
.p-confirmdialog .p-dialog-content{
  overflow: auto;
}

/* overlaypanel */
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-bottom-color: transparent;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-bottom-color: transparent
}
.p-overlaypanel-right-flip:after, .p-overlaypanel-right-flip:before {
  left: auto;
  right: 1.25em;
}
.p-overlaypanel-left-flip:after, .p-overlaypanel-left-flip:before {
  right: auto;
  left: 1.25em;
}

/* openlayers popup */
.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

/* webdatarocks pivot  */
.pivotCustomIcons{
  font-size: 30px !important;
  color: #999 !important;
}
#wdr-popup-options .p-widget{
  font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  text-decoration: none !important;
}
#wdr-popup-options .p-datepicker{
  padding: 0.857em !important;
  min-width: 20em !important;
  background-color: #ffffff !important;
  color: #333333 !important;
  border: 1px solid #a6a6a6 !important;
}
#wdr-popup-options .p-datepicker table{
  width: 100% !important;
  font-size: .9em !important;
  border-collapse: collapse !important;
  margin: 0 0 .4em !important;
}
#wdr-popup-options .p-datepicker td>span, .p-datepicker td>a{
  display: block !important;
  padding: .5em !important;
  text-decoration: none !important;
  border: 0 none !important;
  text-align: center !important;
}
#wdr-popup-options .p-datepicker table td > a, body .p-datepicker table td > span{
  display: block !important;
  text-align: center !important;
  color: #333333 !important;
  padding: 0.5em !important;
  border-radius: 3px !important;
}
#wdr-popup-options .p-datepicker table td > a.p-state-active, body .p-datepicker table td > span.p-state-active{
  color: #ffffff !important;
  background-color: #007ad9 !important;
}
#wdr-popup-options .p-datepicker .p-datepicker-prev span, #wdr-popup-options .p-datepicker .p-datepicker-next span{
  display: block !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  margin-top: -.5em !important;
  margin-left: -.5em !important;
}
#wdr-popup-options .p-datepicker .p-datepicker-header .p-datepicker-title{
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1 !important;
  text-align: center !important;
}
#wdr-popup-options .p-datepicker span.p-datepicker-year{
  margin-left: .25em !important;
}
#wdr-popup-options .p-datepicker .p-datepicker-header .p-datepicker-prev,
#wdr-popup-options .p-datepicker .p-datepicker-header .p-datepicker-next{
  cursor: pointer !important;
  top: 6px !important;
  color: #a6a6a6 !important;
  transition: color 0.2s !important;
}
#wdr-popup-options .p-datepicker .p-datepicker-next{
  right: .125em !important;
}
#wdr-popup-options .p-datepicker .p-datepicker-prev, #wdr-popup-options .p-datepicker .p-datepicker-next{
  position: absolute !important;
  top: .5em !important;
  width: 1.8em !important;
  height: 1.8em !important;
}
#wdr-popup-options .p-corner-all{
  border-radius: 3px !important;
}
#wdr-popup-options .pi{
  font-family: 'primeicons' !important;
}
#wdr-popup-options .p-datepicker .p-datepicker-header{
  padding: 0.429em 0.857em 0.429em 0.857em !important;
  background-color: #ffffff !important;
  color: #333333 !important;
  border-radius: 0 !important;
}
#wdr-popup-options .p-datepicker table{
  font-size: 14px !important;
  margin: 0.857em 0 0 0 !important;
}
#wdr-popup-options .p-datepicker table th{
  padding: 0.5em !important;
  text-align: center !important;
  font-weight: bold !important;
  border: 0 !important;
}
#wdr-popup-options .p-datepicker-title select{
  width: auto !important;
  font-size: inherit !important;
  height: initial;
}

/* loader / progressspinner */

/*
p-progressspinner{
  position: absolute;
  z-index: 9999;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

p-progressspinner .p-progress-spinner-svg{
  border: 16px solid #f3f3f3;
  border-top: 16px solid #6e6e6e;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  animation-duration: 1s!important;

}
p-progressspinner .p-progress-spinner-svg circle{
  display: none;
}
.spinnerTransparent, .spinnerTransparent .p-progress-circle {
  background-color: transparent;
}
*/

/* status color box */
.statusColorCode{
  width:15px;
  height:15px;
  display: inline-block;
  border-radius: 100%;
  /* border: 1px solid #000000; */
  border: 1px solid gray;
  box-shadow: 4px 4px lightgrey;
}

/* tinyMCE editor */
.tox-dialog .tox-dialog__body-nav > .tox-dialog__body-nav-item:last-child, .tox-dialog div[role="tabpanel"] .tox-control-wrap{
  display: none;
}

/* for IE11 */
/*
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  p-progressspinner {
    display: inline-block !important;
    text-align: center;
  }
  p-progressspinner .p-progress-spinner{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
*/

.p-dialog .p-dialog-content p-progressspinner{
  position: relative;
  background:inherit;
  width: 100%;
  height: 200px;
  display: block;
  text-align: center;
  margin: 50px 0 0 0;
}


/* primeng like btn, background and text colors */
.default-primeng-btn{
  border-color: #007ad9 !important;
}
.default-primeng-btn{
  color: #ffffff !important;
  background-color: #007ad9 !important;
  border: 1px solid #007ad9;
}
.default-primeng-btn:hover{
  border: 1px solid #005b9f;
  background-color: #005b9f !important;
}

.bg-default-primeng{
  background-color: #007ad9 !important;
  color: #ffffff !important;
}
.text-default-priemng{
  color: #007ad9;
}
.text-default-priemng:hover{
  color: #005b9f;
}

.cursor-pointer{
  cursor: pointer;
}
.cursor-grab{
  cursor: grab;
}
.cursor-grab {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
 /* "closed-hand" cursor during drag operation. */
.cursor-grab:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.cursor-help {
    cursor: help;
}

/* tag a styled as button */
a.p-button{
  padding: 3px 10px 2px 2px;
  /* padding: 5px 10px 0px 2px; */
  border-radius: 3px;
}
a.p-button > i{
  margin-right: 3px;
  margin-left: 2px;
  vertical-align: middle;
  line-height: 22px;
}


/* col-auto fallback for IE */
.col-auto-2{
  flex: 0 0 16.666667%;
  flex: 0 0 auto;
}

.minw-100{
  min-width: 100% !important;
}

.w-90{
  width: 90%;
}

.p-breadcrumb {
  padding: 0.2rem;
  background: inherit;
}

@media screen and (max-height: 770px){
  .p-button.p-button-text-icon-left .p-button-text{
    padding: 0.38em 0.8em 0.4em 1.8em;
  }
}

/*OVERWRITE PRIMENG's SAGA-BLUE THEME*/

/* dialog */
.p-dialog .p-dialog-header{
  border: 5px solid #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #333 !important;
  padding: 0.5rem !important;
  font-weight: 700 !important;
  border: 0 !important;
}
.p-dialog-title{
  font-family: arial,verdana !important;
  font-size: 13px !important;
}
.p-dialog-header-icon{
  width: 1rem !important;
  height: 1rem !important;
}
.p-dialog .p-dialog-content{
  padding: .571em 1em !important;
  border: 1px solid #c8c8c8;
}
.p-dialog .p-dialog-footer {
  padding: .571em 1em !important;
}
.p-dialog .p-dialog-header-close-icon{
  font-size: 11px !important;
}

/* radio button / checkbox */
p-radiobutton label, p-checkbox label{
  margin-bottom: 0 !important;
}

/* dropdown */
.p-dropdown-panel .p-dropdown-items{
  padding: 0;
}
.p-dropdown-clear-icon{
  font-size: 0.7rem;
  line-height: 1rem;
}

.p-autocomplete .p-button.p-button-icon-only {
  padding: 0;
}

.p-skeleton-animation-none:after{
  animation: none!important;
}

/* bootstrap display problem on primeng button */
[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: initial;
  -moz-appearance: initial;
}

/*primeng fieldset*/
.p-fieldset{
  margin-top: 25px;
}
.p-fieldset .p-fieldset-legend{
  width: auto;
  margin-left: 10px;
  font-size: 14px;
  padding: 10px;
  margin-top: -15px;
}

/* truncate multiple line texts */
.truncate-text{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-text.line-2{
  -webkit-line-clamp: 2;
}

.nonClosableConfDlg {
  max-width: 90vw !important;
}
.nonClosableConfDlg.p-confirm-dialog .p-dialog-content {
  align-items: flex-start !important;
}

.p-inputswitch{
  height: 1.5rem;
}

.inputNumberSpinner button{
    padding: 1px !important;
}

.inputNumberSpinner .p-button-icon{
    font-size: 10px;
}

/* primeng table paginator */
.p-paginator-bottom {
    padding: 0;
}
.p-paginator-rpp-options .p-inputtext {
    padding: 0.5rem 0.5rem;
}

.mandatory {
    color: red;
    top: 5px;
    position: absolute;
}

/* p-speeddial[customSpeeddial].hideSpeeddialToggleButton .p-speeddial-button {
    display: none;
} */
p-speeddial[customSpeeddial] .p-speeddial-button {
    display: none;
}
p-password > div {
    position: relative;
}
p-password > div > i {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    right: 5px;
    cursor: pointer;
}

.zIndex2 {
    z-index: 2;
}

